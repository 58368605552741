import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { CardRow, TypeTitle, TypeDescription } from "./Card"
import Flavor from "./Flavor"

import { chocolatePeanutButter, saltedCaramel } from "../../data/proteinDataEn"

export default function EnglishProtein(props) {
  const { activeFlavor, setActiveFlavor, incomingFlavor } = props

  const images = useStaticQuery(graphql`
    query flavorsProtein {
      chocolatePeanutButter: file(
        relativePath: { eq: "front-page-flavors/protein-pb-chocolate-can.png" }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      saltedCaramel: file(
        relativePath: {
          eq: "front-page-flavors/protein-salted-caramel-can.png"
        }
      ) {
        childImageSharp {
          fixed(height: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <TypeTitle marginTop="5rem">Protein</TypeTitle>
      <TypeDescription>
        With 15g of protein, our protein shakes are ideal for on-the-go
        satisfaction.
      </TypeDescription>

      <CardRow>
        <Flavor
          data={chocolatePeanutButter}
          fixed={images.chocolatePeanutButter.childImageSharp.fixed}
          flavorIndex={9}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />

        <Flavor
          data={saltedCaramel}
          fixed={images.saltedCaramel.childImageSharp.fixed}
          flavorIndex={10}
          activeFlavor={activeFlavor}
          setActiveFlavor={setActiveFlavor}
          incomingFlavor={incomingFlavor}
        />
      </CardRow>
    </>
  )
}
