export const chocolatePeanutButter = {
  id: "proteinChocolatePeanutButter",
  backgroundColor: "#C65100",
  textColor: "#FFFFFF",
  name: "Chocolate Peanut Butter Protein Shake ",
  tagline: "Chocolate Peanut Butter Protein Shake ",
  description:
    "Step up your game with this classic combo! With 18g of plant-based protein, this one's sure to keep you satisfied.",
  ingredients:
    "Water, Sugar, Coconut cream, Pea protein isolate, Dry roasted peanuts, Cocoa, Cocoa processed with alkali, Gum arabic, Natural flavours, Salt, Sunflower lecithin, Tripotassium phosphate, Gellan gum, Carboxymethyl cellulose, Pectin.",
  disclaimer:
    "Contains: Coconut, Peanuts. May contain: Milk, Soy, Tree nuts, Wheat, Eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "310",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "12g",
        percent: "15%",
      },
      {
        type: "secondary",
        label: "Saturated",
        value: "6g",
        percent: "30%",
      },
      {
        type: "secondary",
        label: "+ Trans",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "35g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "3g",
        percent: "11%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "29g",
        percent: "29%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "18g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "410mg",
        percent: "18%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "550mg",
        percent: "12%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "100mg",
        percent: "8%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "2.5mg",
        percent: "14%",
      },
    ],
  },
}

export const saltedCaramel = {
  id: "proteinSaltedCaramel",
  backgroundColor: "#A26528",
  textColor: "#FFFFFF",
  name: "Salted Caramel Protein Shake",
  tagline: "The f'realist Salted Caramel Protein Shake You've Ever Had",
  description:
    "Sweet and salty? Take your protein quest to the next level with the most decadent way to get 15 grams of plant-based protein.",
  ingredients:
    "Water, coconut cream, pea protein isolate, sugar, maltodextrin, caramel, natural flavors, gum acacia, salt, modified cellulose, sunflower lecithin, tripotassium phosphate, gellan gum, almond protein, pectin.",
  disclaimer:
    "Contains coconut, almond. May contain traces of milk, soy, peanuts, tree nuts, wheat, or eggs from manufacturing and blending.",
  nutritionFacts: {
    servingSize: "Per 1 Container (237 mL/355 mL as prepared)",
    calories: "280",
    values: [
      {
        type: "primary",
        label: "Fat",
        value: "7g",
        percent: "9%",
      },
      {
        type: "secondary",
        label: "Saturated",
        value: "5g",
        percent: "25%",
      },
      {
        type: "secondary",
        label: "+ Trans",
        value: "0g",
        percent: "",
      },
      {
        type: "primary",
        label: "Carbohydrate",
        value: "38g",
        percent: "",
      },
      {
        type: "secondary",
        label: "Fibre",
        value: "1g",
        percent: "4%",
      },
      {
        type: "secondary",
        label: "Sugars",
        value: "25g",
        percent: "25%",
      },
      {
        type: "primary",
        label: "Protein",
        value: "15g",
        percent: "",
      },
      {
        type: "primary",
        label: "Cholesterol",
        value: "0mg",
        percent: "0%",
      },
      {
        type: "primary",
        label: "Sodium",
        value: "540mg",
        percent: "23%",
      },
      {
        type: "primary",
        label: "Potassium",
        value: "350mg",
        percent: "7%",
      },
      {
        type: "primary",
        label: "Calcium",
        value: "100mg",
        percent: "8%",
      },
      {
        type: "primary",
        label: "Iron",
        value: "0.3mg",
        percent: "2%",
      },
    ],
  },
}
